var app = (function($){
	var $window = $(window);
	var $body = $('body');

	function init(){
		menuTrigger();
		sticky();
		mail();
		links();
		portfolioClose();
		trackExternalLink();
	}

	function sticky() {
		$window.on('scroll', function(){
			var top = $window.scrollTop();
			if(top > 50){
				$body.addClass('sticky');
			} else {
				$body.removeClass('sticky');
			}
		});
	}

	function mail(){
		var mail = 'ricardo' + '@';
		mail += 'ricardoriogo';
		mail += '.com';

		mail = '<a href="mailto:' + mail + '">' + mail + '</a>';

		$('.mail').html(mail);
	}

	function links() {
		$('a[href^="/"]').on('click', function(e){
			e.preventDefault()
			$('body').removeClass('nav-opened');

			var title = $(this).data('title');
			title = (typeof(title) != 'undefined' ? title += ' - Ricardo Riogo Portfólio' : 'Ricardo Riogo Portfólio');

			History.pushState(null, title, this.href);
		});
	}

	function menuTrigger() {
		$('.menu-trigger').on('click', function(){
			$('body').toggleClass('nav-opened');
		});
	}

	function portfolioClose() {
		$('body').delegate('.portfolio-close', 'click', function(e){
			e.preventDefault();
			$('body').removeClass('open-project');
			$('#project-show').html('');
		})
	}

	function openProject(link){
		$('body').addClass('open-project loading');

		$.get(link, function(result){
			$('#project-show').html(result).waitForImages(function() {
				$('body').removeClass('loading').addClass('loaded');
			});
		});
	}

	function trackExternalLink() {
		$('body').delegate('a[href^=http], a[href^=mailto]', 'click', function(){
			ga('send', 'event', 'External Link', this.href);
		});
	}

	return {
		init: init,
		open: openProject
	}
})(jQuery);

$(function(){
	app.init();
});
