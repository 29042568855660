var contactForm = (function($){

    var $form;

    function init() {
        $form = $('#contactForm');
        $form.validate();
        $form.on('submit', callback);
    }

    function callback(event) {
        event.preventDefault();
        
        var $this  = $(this);
        $this.addClass('sending');

        var action = $this.attr('action');
        var data   = $this.serialize();
        $this.find('textarea, input, button').prop('disabled', true);

        $.post(action, data, function(data){
            if(data.success){
                $form = $(data.form);
                $form.removeClass('sending');
                $form.find('textarea, input, button').prop('disabled', false);
                ga('send', 'event', 'Contact Form', 'sent');
                alert('Mensagem enviada com sucesso!');
                $form[0].reset();
                grecaptcha.reset();
            } else {
                ga('send', 'event', 'Contact Form', 'error');
                alert('Oops! Houve algum erro no envio da sua mensagem. Tente novamente mais tarde.');
            }

            $('[name=csrf_name]').value(data.x);
            $('[name=csrf_value]').value(data.y);
        });
    }

    init();

    return {};
})(jQuery)
