
History.Adapter.bind(window, 'statechange', function(e) {
    e.preventDefault();
    var State = History.getState();
    var hash = State.hash.replace(/(\#\.\/|\.\/|\/)/, '/');
    hash = hash.slice(0, hash.indexOf('?'));

    console.log(hash);

    if(hash == '/'){
        $.scrollTo('#home-page', 'slow', {
            offset: -67
        });
    } else if(hash == '/perfil' || 
                hash == '/portfolio' || 
                hash == '/contato') {

        $.scrollTo(hash.replace('/', '#') + '-page', 'slow', {
            offset: -67
        });
    } else {
        app.open(hash);
    }
    
    // returns { data: { params: params }, title: "Search": url: "?search" }
    
    ga('send', 'pageview', hash);

    // or you could recall search() because that's where this state was saved
    if (State.url == "?search") {
        search(data.params);
    }
});

History.Adapter.onDomLoad(function(){
    var State = History.getState();
    var hash = State.hash.replace(/(\#\.\/|\.\/|\/)/, '/');
    hash = hash.slice(0, hash.indexOf('?'));

    if(hash != '/'){
        $.scrollTo(hash.replace('/', '#') + '-page', 'slow');
    }
});
